import axios, { AxiosInstance, AxiosResponse } from 'axios'
import * as models from './models'
import AuthToken from '@/auth/auth-token'

class PartyDoc {
  protected readonly partydoc: AxiosInstance;

  constructor () {
    const partydoc = axios.create({
      baseURL: process.env.VUE_APP_BASE_API,
      withCredentials: false,
      headers: {
        'Content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
      }
    })

    this.partydoc = partydoc
  }

  handleSuccess (response: AxiosResponse) {
    return response
  }

  public post = async (docId: string, data: models.Party) => {
    await AuthToken.setAuth(this.partydoc)

    return this.partydoc({
      url: `v1/doc/${docId}/party`,
      method: 'POST',
      data: data
    })
  }

  public delete = async (docId: string, partyId: string) => {
    await AuthToken.setAuth(this.partydoc)

    return this.partydoc({
      url: `v1/doc/${docId}/party/${partyId}`,
      method: 'DELETE'
    })
  }
}

export default new PartyDoc()
