<template>
  <v-container class="archive pa-0 ma-0" fluid>
    <v-card>
      <v-card-title>
        <v-toolbar flat style="cursor: default;">
          <v-toolbar-title class="ml-2 title"><v-icon left>mdi-file-upload-outline</v-icon>
            {{ $route.name }} Files
          </v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-col class="d-flex justify-end">
            <v-btn
              id="uploadBtn"
              color="success"
              class="mr-4"
              :loading="loading"
              :disabled="loading"
              @click="saveDocument">
              <v-icon>mdi-file-upload-outline</v-icon>
              Save
            </v-btn>
            <v-btn
              color="warning"
              @click="reset">
              Reset
            </v-btn>
          </v-col>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-form ref="form" lazy-validation v-model="valid">
            <v-row dense>
              <v-col>
                <v-text-field
                  label="Name"
                  v-model="doc.name"
                  dense
                  required
                  :rules="[v => !!v || 'Name is required']"
                  validate-on-blur
                  outlined
                  hide-details="auto">
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Description"
                  v-model="doc.description"
                  dense
                  outlined
                  hide-details="auto">
                </v-text-field>
              </v-col>
            </v-row>
            <DocumentTags
              v-model="doc.tags">
            </DocumentTags>
            <v-row>
              <v-col>
                <v-file-input
                  v-model="files"
                  outlined
                  dense
                  multiple
                  hide-details="auto"
                  truncate-length="100"
                  label="Files"
                  @change="handleFiles">
                </v-file-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <DocPartyTable
                  v-model="docPartyIds"
                  :docId="doc.id || null">
                </DocPartyTable>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script lang="js">
// api
import document from '@/axios/document.ts'
import party from '@/axios/doc-party.ts'
import docfile from '@/axios/file.ts'

// components
import DocPartyTable from '@/components/DocPartyTable.vue'
import DocumentTags from '@/components/DocumentTags.vue'

// mixins
import { displayAlert } from '@/mixins/alert'

export default {
  name: 'upload',
  data () {
    return {
      doc: {
        root_key: '',
        name: '',
        description: '',
        metadata: {},
        tags: {
          category: '',
          subcategory: '',
          status: 'CURRENT'
        }
      },
      docParties: [],
      docPartyIds: [],
      docMeta: [],
      files: [],
      metaheaders: [
        { text: 'Key', value: 'key', sortable: false, filterable: false, divider: true },
        { text: 'Value', value: 'value', sortable: false, filterable: false },
        { text: '', value: 'actions', sortable: false, filterable: false }
      ],
      newFile: false,
      newParty: false,
      valid: true,
      loading: false
    }
  },
  components: { DocPartyTable, DocumentTags },
  mixins: [displayAlert],
  watch: {
    $route: {
      handler (newValue, oldValue) {
        if (newValue) {
          if (newValue.query?.id) {
            this.initDocData()
          } else {
            if (oldValue.query.id) {
              this.resetDocData()
            }
          }
        }
      },
      deep: true
    }
  },
  async created () {
    this.doc.root_key = this.$store.getters.root_key
    if (this.$route.query?.id) {
      await this.initDocData()
    }
  },
  computed: {
    id () {
      return this.$route.query.id || null
    },
    userData () {
      return this.$store.getters.userData
    }
  },
  methods: {
    async initDocData () {
      try {
        const res = await document.get(this.id)
        this.doc = res.data
        if (this.doc.document_file) {
          this.files = [this.doc.document_file]
        }
        if (this.doc.metadata) {
          this.docMeta = Object.keys(this.doc.metadata).map(key => {
            const metaObj = {
              key: key,
              value: this.doc.metadata[key]
            }
            return metaObj
          })
        }
        if (this.doc.parties?.length > 0) {
          const ids = this.doc.parties.map(party => party.party_id)
          this.docPartyIds = JSON.parse(JSON.stringify(ids))
        }
      } catch (err) {
        console.error(err)
        this.emitAlert(true, 'warning', err.message)
      }
    },
    handleFiles (files) {
      const reader = new FileReader()
      for (const [i, file] of files.entries()) {
        reader.onload = (e) => {
          this.files[i].upload_file = e.target.result
        }
        reader.readAsArrayBuffer(file)
      }
    },
    reset () {
      this.$refs.form.reset()
    },
    async saveDocument () {
      try {
        const isValid = this.$refs.form.validate()
        if (isValid !== true) {
          return
        }
        if (this.docPartyIds.length === 0) {
          return this.emitAlert(true, 'warning', 'Please add a party')
        }
        this.loading = true
        if (this.docMeta.length > 0) {
          const entries = this.docMeta.map(obj => {
            return [`${obj.key}`, `${obj.value}`]
          })
          this.doc.metadata = Object.fromEntries(entries)
        }

        if (this.doc.id) await this.updateDoc(this.doc)
        else await this.createDoc()

        if (this.files.length > 0 && this.files[0].upload_file) {
          await this.uploadFile()
        }
        await this.handleDocParties()
        if (this.doc.tags.status === 'CURRENT') {
          // Commenting out this line to prevent updating a current document from archiving other current documents
          //    Documents with at least 1 of the same parties and the same category and subcategory are what is looked for in this comparison
          // await this.updateLatest()
        }
        if (!this.$route.query?.id) {
          this.loading = false
          this.emitAlert(true, 'success', 'Saved!')
          return this.$router.push({
            name: 'Edit',
            query: { id: this.doc.id }
          })
        } else {
          this.loading = false
          this.emitAlert(true, 'success', 'Saved!')
          return this.initDocData()
        }
      } catch (err) {
        this.loading = false
        console.error(err)
        this.emitAlert(true, 'warning', err.message)
      }
    },
    async createDoc () {
      const res = await document.post(this.doc)
      if (res?.data?.id) {
        this.doc.id = res.data.id
      }
      return res
    },
    async updateDoc (doc) {
      if (!doc.root_key) doc.root_key = this.$store.getters.root_key
      return await document.put(doc.id, doc)
    },
    async updateLatest () {
      try {
        // getting updated document data for search
        const doc = await document.get(this.doc.id)
        const partyIds = doc.data.parties.map(p => p.party_id)
        const tags = doc.data.tags

        const res = await document.search(partyIds, tags, 0, 200)
        const docsToUpdate = res.data.filter(d => d.id !== this.doc.id)

        if (docsToUpdate.length > 0) {
          const res = await Promise.allSettled(docsToUpdate.map(async d => {
            d.tags.status = 'ARCHIVE'
            return await this.updateDoc(d)
          }))
          const rejected = res.flatMap(result => {
            return result.status === 'rejected'
              ? result.reason
              : []
          })
          if (rejected.length > 0) throw rejected
          else return res
        }
        return
      } catch (err) {
        console.error(err)
        throw err
      }
    },
    async uploadFile () {
      try {
        const file = this.files[0]
        const blob = new Blob([file.upload_file], { type: `${file.type}` })
        const { category, subcategory } = this.doc.tags
        const formData = new FormData()

        const pathEnv = `${process.env.VUE_APP_FILE_PATH}`

        formData.append('path', `${pathEnv}/${category}/${subcategory}/`)
        formData.append('name', `${file.name}`)
        formData.append('upload_file', blob, `${file.name}`)

        return await docfile.put(this.doc.id, formData)
      } catch (err) {
        console.error(err)
        throw err
      }
    },
    async handleDocParties () {
      const partiesToAdd = (this.docPartyIds.length > 0)
        ? this.docPartyIds.filter(id => {
          if (!this.doc.parties || this.doc.parties?.length === 0) {
            return id
          }
          const matches = this.doc.parties.filter(p => p.party_id === id)
          if (matches.length === 0) {
            return id
          }
        })
        : []
      const partiesToDelete = (this.doc?.parties?.length > 0)
        ? this.doc.parties.flatMap(party => {
          return (!this.docPartyIds.includes(party.party_id))
            ? party.party_id
            : []
        })
        : []
      return await Promise.allSettled([
        this.updateDocParties(partiesToAdd, 'post'),
        this.updateDocParties(partiesToDelete, 'delete')
      ]).catch(err => {
        throw err
      })
    },
    async updateDocParties (partyIds, action) {
      if (partyIds.length === 0) return []
      const results = await Promise.allSettled(partyIds.map(async id => {
        if (action === 'post') {
          return await party.post(this.doc.id, { party_id: id })
        } else if (action === 'delete') {
          return await party.delete(this.doc.id, id)
        }
      }))
      const rejected = results.flatMap(result => {
        return result.status === 'rejected'
          ? result.reason
          : []
      })
      if (rejected.length > 0) throw rejected
      else return results
    },
    resetDocData () {
      this.doc = {
        root_key: this.$store.getters.root_key,
        name: '',
        description: '',
        metadata: {},
        tags: {
          category: '',
          subcategory: '',
          status: 'CURRENT'
        }
      }
      this.docPartyIds = []
      this.docMeta = []
      this.files = []
    }
  }
}
</script>
