<template>
  <v-card flat outlined>
    <v-toolbar flat color="#fff">
      <v-toolbar-title class="body">Parties with Access</v-toolbar-title>
      <v-divider vertical inset class="mx-4"></v-divider>
      <v-btn-toggle
        dense
        v-model="addParties"
        mandatory
        :active-class="'activeToggle'"
        class="btnGroup">
        <v-btn
          height="38"
          @click="addParties = false; search = ''"
          style="border-right:1px solid #9e9e9e;">
            <small>All</small>
            <v-icon class="ml-1" small>
              mdi-format-list-bulleted
            </v-icon>
        </v-btn>
        <v-btn
          height="38"
          @click="addParties = true"
          style="border-top-right-radius:0px;border-bottom-right-radius:0px;">
          <small>Add Parties</small>
          <v-icon class="ml-1" small>
            mdi-plus
          </v-icon>
        </v-btn>
      </v-btn-toggle>
      <v-col cols="3" class="pl-0" style="height:64px;">
        <v-text-field
          :label="addParties ? 'Search Parties' : 'Filter Parties'"
          outlined
          dense
          style="border-top-left-radius: 0px; border-bottom-left-radius: 0px;"
          @click:append="search = ''"
          append-icon="mdi-window-close"
          v-model="search">
          <template v-slot:prepend-inner>
            <v-icon>
              {{ addParties ? 'mdi-magnify' : 'mdi-filter-outline' }}
            </v-icon>
          </template>
        </v-text-field>
      </v-col>
    </v-toolbar>
    <v-divider></v-divider>
    <v-data-table
      height="300px"
      :loading="loading"
      :headers="headers"
      :search.sync="search"
      :items.sync="tableItems"
      :items-per-page="100"
      :footer-props="{ 'items-per-page-options': [50, 100, 200, -1] }"
      item-key="id"
      fixed-header
      :no-data-text="addParties ? 'Please search for a party' : 'No data to show'"
      dense>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top open-delay="500" v-if="addParties">
          <template v-slot:activator="{ on }">
            <v-btn icon small
              v-on="on"
              color="green"
              :disabled="docPartyIds.includes(item.id)"
              @click="add(item)">
              <v-icon>mdi-plus-circle</v-icon>
            </v-btn>
          </template>
          <span>Add</span>
        </v-tooltip>
        <v-tooltip top open-delay="500" v-else>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon small @click="handleRemove(item)">
              <v-icon color="red">mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
          <span>Remove</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-dialog justify="center" v-model="removeDialog" persistent width="500">
      <v-card class="ma-0 pa-6">
        <v-row justify="center">
          <v-icon x-large color="orange">mdi-alert-circle-outline</v-icon>
        </v-row>
        <v-row justify="center" class="py-6">
          <h3>Remove {{ docParty.name }}?</h3>
        </v-row>
        <v-row justify="center" class="py-4">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" fab small color="red" class="elevation-3 white--text" @click.stop="removeDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Cancel</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" fab small color="green" class="white--text elevation-3 ml-2" @click="remove">
                <v-icon>mdi-check</v-icon>
              </v-btn>
            </template>
            <span>Confirm</span>
          </v-tooltip>
        </v-row>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script lang="js">
// mixins
import { displayAlert } from '@/mixins/alert'
import { fullwidth } from '@/mixins/fullwidth'
import { debounce } from 'lodash'
import { partySearch } from '@/mixins/party-search'

export default {
  name: 'DocPartyTable',
  data () {
    return {
      search: '',
      addParties: false,
      removeDialog: false,
      docParty: {},
      docParties: [],
      docPartyIds: [],
      parties: []
    }
  },
  mixins: [displayAlert, fullwidth, partySearch],
  props: ['value', 'docId'],
  watch: {
    docPartyIds: {
      handler (newValue) {
        this.$emit('input', newValue)
      },
      deep: true
    },
    value: {
      handler (newValue) {
        this.docPartyIds = newValue
        this.initDocParties()
      },
      deep: true
    },
    search: {
      handler () {
        this.tableSearch()
      }
    },

  },
  created () {
    if (this.value?.length > 0) {
      this.docPartyIds = this.value
      this.initDocParties()
    }
    this.debounceBind = debounce(this.searchParties, 500)
  },
  computed: {
    tableItems () {
      return this.addParties ? this.parties : this.docParties
    },
    headers () {
      return [
        { sortable: false, filterable: false },
        { text: 'Party Name', sortable: false, filterable: true, value: 'name', align: 'left' },
        { text: 'Party Type', sortable: false, filterable: true, value: 'party_type_name', align: 'center' },
        { text: `${this.addParties ? 'Add' : 'Remove'}`, align: 'center', value: 'actions', sortable: false, filterable: false }
      ]
    },
    tableSearch () {
      return this.addParties ? this.debounceBind : this.initDocParties
    }
  },
  methods: {
    async initDocParties () {
      if (this.docPartyIds.length === 0) {
        return this.docParties = []
      }
      try {
        const term = this.docPartyIds.join(' ')
        const results = await this.queryParties(term, 'or')
        this.docParties = results
      } catch (err) {
        this.handleError(err)
      }
    },
    async searchParties () {
      if (!this.search) return
      this.loading = true
      try {
        const results = await this.queryParties(this.search)
        this.parties = results
      } catch (err) {
        this.handleError(err)
      } finally {
        this.loading = false
      }
    },
    add (party) {
      this.docPartyIds.push(party.id)
    },
    handleRemove (party) {
      this.docParty = party
      this.removeDialog = true
    },
    remove () {
      const index = this.docPartyIds.indexOf(this.docParty.id)
      this.docPartyIds.splice(index, 1)
      this.docParty = {}
      this.removeDialog = false
    }
  }
}
</script>

<style>
.btnGroup {
  border-top: 1px solid #9e9e9e;
  border-left: 1px solid #9e9e9e;
  border-bottom: 1px solid #9e9e9e;
  border-top-right-radius:0px !important;
  border-bottom-right-radius:0px;
}
</style>
